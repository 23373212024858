<template>
  <v-card>
    <v-card-title>
      <div class="font-weight-regular"><strong>Channel: </strong> {{ term.channel }}</div>
      <v-spacer />
      <v-btn @click="startEditing" color="primary" class="px-5" v-if="canEdit" :disabled="edit">
        Edit
      </v-btn>
      <!-- <v-btn @click="$emit('delete', term)" small color="warning" class="mx-1"> Delete </v-btn> -->
    </v-card-title>
    <v-divider />

    <v-card-text class="text--monospaced text-left terms-text" v-if="!edit">
      <pre class="terms">{{ terms }}</pre>
    </v-card-text>

    <form v-else @submit.prevent.stop="submitData">
      <v-textarea
        v-model="terms"
        solo
        label="Enter terms and conditions"
        rows="15"
        hide-details
        class="text--monospaced ma-2"
      />

      <v-divider />

      <v-card-actions class="ma-0 py-3 px-5 lighten-4">
        <v-spacer />
        <v-btn color="success" type="submit" class="px-10">Save</v-btn>
      </v-card-actions>
    </form>
  </v-card>
</template>

<script>
import { TERMS_ITEM_UPDATE } from "@/constants/api";

export default {
  props: {
    term: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    edit: false,
    terms: null,
    loading: false,
  }),
  watch: {
    term() {
      this.terms = this.term.terms;
    },
  },
  mounted() {
    this.terms = this.term.terms;
  },
  computed: {
    updateUrl() {
      return TERMS_ITEM_UPDATE.replace(":channel:", this.term.channel);
    },
    canEdit() {
      return this.verifyPrivilege(this.updateUrl);
    },
  },
  methods: {
    startEditing() {
      this.edit = true;
    },
    async submitData() {
      try {
        this.loading = true;
        await this.$axios.post(this.updateUrl, this.terms);
        this.$iziToast.success({ title: "Success" });
        this.edit = false;
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.terms-text {
  pre.terms {
    color: black;
    white-space: pre-wrap;
    min-height: 500px;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
